import React, { useEffect, useState, useRef } from 'react'
import HomeBlock from './HomeBlock'
//import HomeVideoModal from './HomeVideoModal'
import { newBlockData } from './homeData'
import HomeTextBlock from './HomeTextBlock'
import ReactHlsPlayer from '@panelist/react-hls-player/dist'

import reGif from '../../images/home-regif.gif'
import bmwGif from '../../images/home-bmw.gif'
import hb1 from '../../images/home-toyota-gazoo.jpeg'
import hb2 from '../../images/pu-img-banner-up-2.jpg'
import hb3 from '../../images/home-misfit-wrapping.jpeg'
import hb4 from '../../images/home-from-where-imini.jpeg'
import hb5 from '../../images/home-michelin-family.png'
import hb6 from '../../images/home-cannacon.jpeg'
import hb7 from '../../images/home-jt.gif'
import hb8 from '../../images/home-giving-kitchen.png'
import hb9 from '../../images/home-toyota-motor-thrills.jpeg'
import hb10 from '../../images/home-landrover-leadership.jpeg'

import bmwVid from '../../images/home-bmw-posters.mp4'
import michelinVid from '../../media/michelin-hero-vid.mp4'
import { StaticImage } from 'gatsby-plugin-image'

import { useInterval } from './helpers'
import { useMemo } from 'react'

import VolumeOff from '../../images/svg/volume_off.svg'

export default function HomeBlocks(props) {
    
    const { projectID, rect, expanded, videoOpen } = props
    
    const [currentScroll, setCurrentScroll] = useState(0)
    const [scrolling, setScrolling] = useState(false)
    const [muted, setMuted] = useState(true)
    const videoMain = useRef(null)

    const navData = {
        projectID,
        rect
    }
    const mobileScroll = () => {
        const wh = window.innerHeight * 0.50
        const wh1 = window.innerHeight * 0.37
        
        let el = null
        let top, blockEls = document.querySelectorAll('.itemBlock')
        
        const topEltop = blockEls[0].getBoundingClientRect().top

        for (let i=0; i<blockEls.length; i++) {
            top = blockEls[i].getBoundingClientRect().top
            if (i === 0 && top < wh1 && top > 0 && el === null) {
                el = blockEls[i]
            } else {
                if (topEltop < 0 && el === null && i !== 0 && top > 0) {
                    el = blockEls[i]
                } else {
                    blockEls[i].classList.remove('mobileShow')
                }
            }
        }

        if (el !== null) {
            el.classList.add('mobileShow')
        }
    }
    
    /* const scrollHandler = () => {
        setCurrentScroll(window.scrollY)
    } */
    
    const handleScroll = () => {
        if (window.innerWidth < 800) {
            mobileScroll()
        }
    }
     const adjustMute = () => {
       setMuted(!muted)
       console.log(muted)
    }
    useInterval(()=>{
        if (typeof window === "undefined") return
        if (window.innerWidth < 800) return
        
        const y = window.scrollY
        const cS = currentScroll

        setScrolling(sc => y !== cS)

        setCurrentScroll(csc => y)
    }, 100)
    const [hasWindow, setHasWindow] = useState(false)

    useEffect(() => {
         if (typeof window !== 'undefined') setHasWindow(true)
        if (window.innerWidth < 800) {
            window.addEventListener("scroll", handleScroll)
            
        }
        return () => {
            if (window.innerWidth < 800) {
                window.removeEventListener("scroll", handleScroll)
            }
        }
      }, [])
    const rvideo = useMemo(()=>(<ReactHlsPlayer
        className={'homeTopVideo'}
        src={"https://stream.mux.com/nInWYYNJ2Z4200iHynU4fUPZ01dEkMjYk022tR02ZxM4SYE.m3u8"}
        autoPlay={true}
        controls={true}
        controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        loop={true}
        muted={muted}
        disablePictureInPicture={true}
        width="100%"
        height="auto"
    />), [muted])

    return (
        <div className={'home-wrapper'}>
            <div className={'page-header-wrapper'}>
                <p className={'page-header'}>
                    Public School is a&nbsp;
                    <a className="iconNextArrow" href="/info">creative studio</a> 
                    &nbsp;that helps brands find their way through new audiences and culture.
                </p>
            </div>
            <div className={"home-banner-wrapper"}>
                <div ref={videoMain} className={'home-video-wrapper'}>
                {hasWindow && <>{rvideo }</> }
                </div>
                {/* <VolumeOff onClick={adjustMute}/> */}
            </div>

            <div id={'home-blocks'} className={`col ${scrolling ? 'isScrolling' : 'notScrolling'}`}>
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-ray-ban`}
                    blockimg={<img alt="static-img" loading="lazy" src={newBlockData[0].img} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`} />}
                    block={newBlockData[0]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[0] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-mclaren-uncovered`}
                    blockimg={<img alt="static-img" loading="lazy" src={newBlockData[1].img} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`} />}
                    block={newBlockData[1]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[1] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-toyota-gazoo`}
                    blockimg={<img alt="static-img" loading="lazy" src={hb1} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`} />}
                    block={newBlockData[2]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[2] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-porsche-unseen`}
                    blockimg={<img alt="static-img" loading="lazy" src={hb2} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[3]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[3] === projectID ? navData : false}
                />
                <HomeTextBlock
                    key={`homeBlock-misfit-wrapping-co`}
                    scrolling={scrolling}
                    blockimg={<img alt="static-img2" loading="lazy" src={hb3} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[4]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[4] === projectID ? navData : false}
                    />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-from-where-i-mini`}
                    blockimg={<img alt="static-img3" loading="lazy" src={hb4} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[5]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[5] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-babo`}
                    blockimg={<img alt="static-img4" src={newBlockData[6].img} className={`iconNavArrow homeblock-img`}/>}
                    block={newBlockData[6]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[6] === projectID ? navData : false}
                />
                
                <div id={`block-regif`} className={'itemBlock'}>
                    <img src={reGif} alt={'reGif'} className={'itemBlock-img'} />
                </div>

                <HomeTextBlock
                    key={`homeBlock-michelin-guide-virtual-family-meal`}
                    scrolling={scrolling}
                    blockimg={<img alt="static-img5" loading="lazy" src={hb5} className={`iconNavArrow homeblock-img michelin-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[7]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[7] === projectID ? navData : false}
                >
                    <div className={'itemBlock-video-wrapper'}>
                        <video className={'itemBlock-video'} loop autoPlay muted>
                            <source src={michelinVid} type="video/mp4"/>
                        </video>
                    </div>
                </HomeTextBlock>
                
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-cannacon`}
                    blockimg={<img alt="static-img6" loading="lazy" src={hb6} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[8]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[8] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-jamestown-ultimate-showdown`}
                    blockimg={<img alt="static-img7" loading="lazy" src={hb7} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[9]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[9] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-the-giving-kitchen`}
                    blockimg={<img alt="static-img8" loading="lazy" src={hb8} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[10]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[10] === projectID ? navData : false}
                />
                <HomeTextBlock
                    key={`homeBlock-toyota-motor-thrills9`}
                    scrolling={scrolling}
                    blockimg={<img alt="static-img" loading="lazy" src={hb9} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[11]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[11] === projectID ? navData : false}
                />
                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-toyota-social`}
                    blockimg={<img alt="static-img10" loading="lazy" src={newBlockData[12].img} className={`iconNavArrow homeblock-img`}/>}
                    block={newBlockData[12]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[12] === projectID ? navData : false}
                />

                {/* <div id={`block-bmw-posters`} className={'itemBlock'}>
                    <video className={'itemBlock-video'} preload={'auto'} loop autoPlay muted>
                        <source src={bmwVid} type="video/mp4"/>
                    </video>
                    <img src={bmwGif} alt={'bmwVid'} className={'itemBlock-img'} />
                </div> */}

                <HomeBlock
                    scrolling={scrolling}
                    key={`homeBlock-jaguar-landrover-leadership-summit`}
                    blockimg={<img loading="lazy" alt="static-img12" src={hb10} className={`iconNavArrow homeblock-img`} imgclassname={`iconNavArrow`}/>}
                    block={newBlockData[13]}
                    prevRect={rect}
                    navExpand={expanded}
                    navData={expanded && newBlockData[13] === projectID ? navData : false}
                />
            </div>
        </div>
    )
}
