import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import HomeBlocks from "../components/home/HomeBlocks";
import Mouse from "../components/mouse/Mouse";
import LoadingScreen from "../components/loading/LoadingScreen";

const HomeTemplate = (props) => {
  //const block = props.location?.state?.block || null
  const projectID = props.location?.state?.projectID || null;
  const rect = props.location?.state?.rect || null;
  //const rect = { top:0, left:0, width: '100vw', height: '49.375vw' }
  const expanded = false;
  const homeNavProps = {
    projectID,
    rect,
    expanded,
  };
  return (
    <>
      <LoadingScreen />
      <Layout data={props} pageID={"page-home"}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
          />
          <meta charSet="utf-8" />
          <title>Public School</title>
          <meta
            name="description"
            content="Public School is a creative studio that helps brands find their way through new audiences and culture. We are storytellers and moment makers."
          />
          <meta name="referrer" content="origin" />
        </Helmet>

        <section className="container">
          <HomeBlocks {...homeNavProps} />
        </section>
        <Mouse />
      </Layout>
    </>
  );
};

export default HomeTemplate;
