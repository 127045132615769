import React, { useEffect, useRef, useState } from "react";
import TopLogo from "../../images/svg/load-1.svg";
import BtmLogo from "../../images/svg/load-2.svg";
import loadingAnim from "../../media/loading-anim.mp4";

import FontFaceObserver from "fontfaceobserver";

const Loadingscreen = () => {
  const loadingWrapRef = useRef(null);
  const loadingScreenRef = useRef(null);
  const topLogo = useRef(null);

  const [loading, setLoading] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const font = new FontFaceObserver("Matter");
  const font2 = new FontFaceObserver("Matter SQ");
  const font3 = new FontFaceObserver("newsreader");
  const loaded = false;

  useEffect((fontsLoaded) => {

    const todayTimeStamp = new Date().getTime();

    const oneHourTimeStamp = 1000 * 60 * 60;

    // if local storage has ps-site-loading-screen
    const localStorageHasLoadingScreen = localStorage.getItem("ps-site-loading-screen");

    // if local storage has ps-site-loading-screen and its value is less than a day ago
    if (localStorageHasLoadingScreen && todayTimeStamp - localStorageHasLoadingScreen < oneHourTimeStamp) {
      setLoading(false);
      document.body.style.position = "relative";
      console.log("already loaded")
      return;
    }

    setLoading(true);

    document.body.style.position = "fixed";

    const loadingScreen = () => {
      loadingWrapRef.current.style.animationPlayState = "running";
      loadingScreenRef.current.style.animationPlayState = "running";
      setTimeout(() => {
        localStorage.setItem("ps-site-loading-screen", todayTimeStamp);
        setLoading(false);
      }, 2500);
      setTimeout(() => {
        document.body.style.position = "relative";
      }, 2000);
    };
    // topLogo.current.children[0].classList.add('top-logo-moved')
    font.load().then(() => {
      font2.load().then(() => {
        font3.load().then(() => {});
      });
    });
    setTimeout(() => {
      if (loadingWrapRef.current) {
        loadingScreen();
      }
    }, 3000);
  }, []);

  return loading ? (
    <div ref={loadingScreenRef} className="loading-screen">
      <div ref={loadingWrapRef} className="loading-wrap">
        <div ref={topLogo} className="topLogo-Wrap">
          <TopLogo className="top-logo" />
        </div>
        <BtmLogo className="btm-logo" />
      </div>
      <div ref={loadingWrapRef} className="safari">
        <video width="100%" height="100%" autoPlay muted>
          <source src={loadingAnim} type="video/mp4" />
        </video>
      </div>
    </div>
  ) : null;
};

export default Loadingscreen;
