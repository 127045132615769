import React, { useRef, useState, useEffect } from 'react'

//import { StaticImage } from "gatsby-plugin-image"

import HomeProjectBlock from './HomeProjectBlock'

function HomeTextBlock(props) {
    const { block, navData, navExpand, prevRect, children, blockimg, scrolling } = props
    const { id, img, project, imgUrl, textBlockParts, title, tag, video, videoUrl } = block

    const [expanded, setExpanded] = useState(navExpand)
    const [rect, setRect] = useState(navData ? prevRect : false)
    const [titleStyle, setTitleStyle] = useState({})
    const [textDirection, setTextDirection] = useState('right')
    const [isHover, setIsHover] = useState(false)
    const [onscreen, setOnscreen] = useState(false)
    const scrollRef = useRef(null)
    const [isMobile, setIsMobile] = useState(false)

    const projectRef = useRef(null)
    const imgRef = useRef(null)

    function handleExpanded(e) {
        if (typeof document === 'undefined') return

        if (projectRef?.current?.contains(e.target)) return

        const r = imgRef?.current?.getBoundingClientRect()
        setRect(r)

        setExpanded(!expanded)
    }
    
    const handleMouseMove = (e) => {
        if (expanded) return
        
        if (window.innerWidth < 800) return

        if (e.clientX > window.innerWidth / 2) {
            setTextDirection('right')
            setTitleStyle({
                top: `${e.clientY}px`,
                left: `${e.clientX}px`,
                transform: `translateX(calc(-100% - 80px))`
            })
        } else {
            setTextDirection('left')
            setTitleStyle({
                top: `${e.clientY}px`,
                left: `${e.clientX + 80}px`
            })
        }
    }
    
    const handleClose = () => {
        if (typeof document === 'undefined') return

        setExpanded(false)
    }

    const buildProject = (blockProps) => {
        return <HomeProjectBlock {...blockProps} blockimg={blockimg} titleStyle={titleStyle} textDirection={textDirection}/>
    }
    
    function handleMouseEnter() {
        if (window.innerWidth < 800) return
        setIsHover(true)
    }

    function handleMouseLeave() {
        if (window.innerWidth < 800) return
        setIsHover(false)
    }

    function handleScroll() {
        if (window.innerWidth > 800) return
        const r = scrollRef?.current?.getBoundingClientRect()
        if (r.top < window.innerHeight && r.top > 0) {
            setOnscreen(true)
        } else {
            setOnscreen(false)
        }
    }

    useEffect(() => {
        if (typeof document === 'undefined') return
        
        setIsMobile(window.innerWidth < 800)

        window.addEventListener("scroll", handleScroll)
        
        return () => {
          window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    return (
        <div 
            ref={scrollRef}
            id={id} 
            className={'itemBlock textBlock'} 
            onClick={handleExpanded} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>

            <div className={`itemBlock-hover ${expanded ? 'img-disabled' : ''}`} onMouseMove={handleMouseMove}></div>

            <div className={'itemBlock-textBlock-wrapper'} onMouseMove={handleMouseMove}>
                {textBlockParts.map(textblockpart => <span key={`${textblockpart}`}
                    className={'itemBlock-textBlock-part iconNavArrow'}>
                        {textblockpart}
                    </span>)}
            </div>
            
            <p className={'itemBlock-title'}>{title}</p>
            
            <div style={titleStyle} className={
                [
                    `itemBlock-title-dark`,
                    `hoverTextBlock`,
                    `hoverTextDirection-${textDirection}`,
                    `${(isHover) ? 'hovering' : ''}`,
                    `${scrolling || (!scrolling && !isHover) ? 'hide' : ''}`
                ].join(' ')
            }>
                <div ref={imgRef} className={'hoverTextBlock-img'}>
                    {blockimg}

                    {isHover || (isMobile && onscreen) ? children : null}
                    
                </div>
                <div className={`hoverTextBlock-title`}>
                    {title}
                </div>
            </div>
            
            <p className={'itemBlock-tag'}>{tag}</p>

            <div className={`itemBlock-project ${expanded ? 'expanded' : ''}`} ref={projectRef}>
                {expanded ? buildProject({block, rect, handleClose}) : null}
            </div>
        </div>
    )
}

export default HomeTextBlock