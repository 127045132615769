import React, { useRef, useState } from 'react'

import { StaticImage } from "gatsby-plugin-image"

import HomeProjectBlock from './HomeProjectBlock'

function HomeBlock(props) {
    const { block, navData, navExpand, prevRect, children, blockimg, scrolling } = props
    const { id, img, imgUrl, title, tag, hoverTextBlockParts, video, videoUrl } = block

    const [expanded, setExpanded] = useState(navExpand)
    const [rect, setRect] = useState(navData ? prevRect : false)
    const [titleStyle, setTitleStyle] = useState({})
    const [textDirection, setTextDirection] = useState('right')

    const projectRef = useRef(null)
    const imgRef = useRef(null)

    function handleExpanded(e) {
        if (typeof document === 'undefined') return

        if (projectRef?.current?.contains(e.target)) return

        setRect(imgRef?.current?.getBoundingClientRect())
        setExpanded(!expanded)
    }
    
    const handleMouseMove = (e) => {
        if (expanded) return

        if (window.innerWidth < 800) return
        
        if (e.clientX > window.innerWidth / 2) {
            setTextDirection('right')
            setTitleStyle({
                top: `${e.clientY}px`,
                left: `${e.clientX}px`,
                transform: `translateX(calc(-100% - 80px))`
            })
        } else {
            setTextDirection('left')
            setTitleStyle({
                top: `${e.clientY}px`,
                left: `${e.clientX + 80}px`
            })
        }

    }
    
    const handleClose = () => {
        if (typeof document === 'undefined') return

        setExpanded(false)
    }

    const buildProject = (blockProps) => {
        return <HomeProjectBlock {...blockProps} blockimg={blockimg} titleStyle={titleStyle} textDirection={textDirection}/>
    }

    const hoverTitleBlock = () => {
        const blockClasses = [
            'itemBlock-title-dark',
            `hoverTextDirection-${textDirection}`,
        ]

        if (scrolling) blockClasses.push('scrolling')
        
        return (
            <div style={titleStyle} className={blockClasses.join(' ')}>
                <div className={'itemBlock-hover-title'}>{title}</div>
                <div className={'itemBlock-hover-parts'}>
                    {hoverTextBlockParts.map((part, i) => {
                        const hoverPartClasses = ['hoverText-part']
                        
                        if (i === 0 || i === 1) hoverPartClasses.push('hoverText-part-first')

                        return (<span key={`hoverPart-${title}-${i}`} className={hoverPartClasses.join(' ')}>
                            {part}
                        </span>)
                    })}
                </div>
            </div>
        )
    }    

    return (
        <div id={id} className={'itemBlock iconNavArrow'} onClick={handleExpanded}>
            <div ref={imgRef} className={`iconNavArrow itemBlock-hover ${expanded ? 'img-disabled' : ''}`}  onMouseMove={handleMouseMove}>
                {blockimg}
            </div>

            <p className={'itemBlock-title'}>{title}</p>
            {expanded ? '' : hoverTitleBlock()}
            <p className={'itemBlock-tag'}>{tag}</p>

            <div className={`itemBlock-project ${expanded ? 'expanded' : ''}`} ref={projectRef}>
                {expanded ? buildProject({block, rect, handleClose}) : null}
            </div>

            { children }
            
        </div>
    )
}

export default HomeBlock