import React, { useEffect, useState } from 'react'

import { navigate } from "gatsby"

function HomeProjectBlock(props) {
    const { block, titleStyle, textDirection, rect, handleClose, blockimg } = props
    const { id, img, imgUrl, title, hoverTextBlockParts, textBlockParts, textBlock, project } = block

    const initialRect = {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height
    }

    const oldWidth = () => {
        if (project === "toyota-gazoo") return "46.875rem"
        if (project === "porsche-unseen") return "72.5rem"
        if (project === "jaguar-landrover-leadership-summit") return "40rem"
        if (project === "misfit-wrapping-co") return "45rem"
        if (project === "from-where-i-mini") return "40rem"
        if (project === "babo") return "55rem"
        if (project === "michelin-guide-virtual-family-meal") return "73.264rem"
        if (project === "cannacon") return "50rem"
        if (project === "jamestown-ultimate-showdown") return "55rem"
        if (project === "the-giving-kitchen") return "57rem"
        if (project === "toyota-motor-thrills") return "71.78rem"
        if (project === "toyota-social") return "48rem"
        return "50rem"
    }

    const [setup, setSetup] = useState(false)
    const [imageStyle, setImageStyle] = useState(initialRect)
    const [newTitleStyle, setNewTitleStyle] = useState({
        top: `${titleStyle.top}`,
        left: `${titleStyle.left}`,
        transform: `${titleStyle.transform}`,
        width: oldWidth(),
        maxWidth: oldWidth(),
        objectPosition: project === "babo" ? "center" : "0 25%"
    })

    useEffect(() => {
        if (project === "babo") {
            setImageStyle({
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%',
                objectPosition: '0 25%'
            }) 
        } else {
            setImageStyle({
                top: 0,
                left: 0,
                width: '100%',
                maxWidth: 'unset',
                height: '100%'
            })
        }

        setNewTitleStyle({
            top: `calc(100% - 9.5rem)`,
            transform: `translateX(0%)`,
            left: 0,
            width: `200rem`,
            maxWidth: `unset`,
            opacity: '1'
        })

        setSetup(true)
        
        setTimeout(() => {

            navigate(`/project/${block.project}`,{state: {
                rect: rect,
                //currentScroll: document.documentElement.scrollTop
            }})
        }, 500)

    },[setSetup,setImageStyle,navigate,block, rect])

    const isSetupClass = setup ? 'is-setup' : ''

    
    const blockparts = textBlock ? textBlockParts : hoverTextBlockParts
    return (
        <div id={`project-${id}`} className={`project-view ${isSetupClass} home-project-view-expanded`}>
            <div className={"project-banner-wrapper"}>
                {/* <img src={img} style={imageStyle} className={'project-image'} alt="project-image"/> */}
                {/* <StaticImage src={`${imgUrl}`} className={'project-image'} alt={"project-image"}/> */}
                <div style={imageStyle} className={'project-image'}>
                    {blockimg}
                </div>

                <div style={newTitleStyle} className={`itemBlock-hover-parts hoverTextDirection-${textDirection}`}>
                    {blockparts.map((part, i) => {
                        const hoverPartClasses = ['hoverText-part']
                        
                        if (i === 0) hoverPartClasses.push('hoverText-part-first')

                        return (<span key={`hoverPart-${title}-${i}`} className={hoverPartClasses.join(' ')}>
                            {part}
                        </span>)
                    })}
                </div>

            </div>
            <div className={'project-close iconClose'}></div>
        </div>
    )
}

export default HomeProjectBlock